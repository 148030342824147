import React from "react";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import { useUploadFileMutation } from "../../../services/auth";
import { FileLoader, UploadAdapter } from "@ckeditor/ckeditor5-upload/src/filerepository";
import { editorConfig } from "../../molecules/editor/editor.config";
import TextInput from "../../atoms/text-input";
import CustomButton from "../../atoms/button";
import { CKEditor as CKEditorComponent } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5";
import ImageUpload from "../../atoms/image-upload";

export type PitchDeckProps = {
  title?: string
  description?: string
  imageUrl?: string

  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const PitchDeck = ({ title, description, imageUrl, formik, index }: PitchDeckProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const [uploadFile] = useUploadFileMutation();

  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const res = await uploadFile({ image: file }).unwrap();
            resolve({
              default: res?.data?.image_name,
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => {},
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return uploadAdapter(loader);
    };
  }

  const customEditorConfig = {
    ...editorConfig,
    extraPlugins: [uploadPlugin],
  };

  if(!editMode) {
    return (
      <div className="py-[80px] flex flex-row items-center bg-black text-white group relative">
        <div className="container">
          <div className="flex flex-col md:flex-row flex-nowrap justify-center gap-20 px-[20px] md:px-0">
            {imageUrl ? (
              <div className="flex justify-center items-center md:w-[20%]">
                <img src={imageUrl} alt="Putiton Badge" className="w-full block" />
              </div>
            ) : null}
            <div className="w-full md:max-w-[60%] py-10">
              <h1
                className="font-bold text-4xl leading-[2.5rem] mb-[20px]">
                {title}
              </h1>
              <p className="text-[1.2rem] leading-[1.6rem]"
                 dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, "<br />") || "" }}
              >
              </p>

              <div className="mt-40">
                <div className="flex flex-row flex-nowrap">
                  <input type="email" placeholder="Email"
                         className="bg-dark-input-background text-gray-100 pl-30  pr-30 py-10 rounded-l-3xl placeholder:text-gray-100 outline-0" />
                  <button
                    className="bg-primary-color text-black uppercase rounded-3xl px-20 font-bold -ml-[30px]">Download
                    our pitch deck
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24">
      <div className="flex flex-row gap-10 flex-nowrap">
        <div className="grow">
          <TextInput
            name={`elements.${index}.params.title`}
            type="text"
            label="Section title"
            value={formik?.values.elements[index]?.params?.title}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
            className="mb-20"
          />

          <div>
            <label>Section description</label>
            <CKEditorComponent
              config={customEditorConfig}
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor.getData();
                formik?.setFieldValue(`elements.${index}.params.description`, data);
              }}
              data={description || ''}
              onReady={() => {
              }}
            />
          </div>
        </div>

        <div className="w-200px">
          <ImageUpload
            label="Image"
            name={`elements.${index}.params.imageUrl`}
            value={imageUrl}
            className="bg-gray-400"
            onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.imageUrl`, img)}
            image={imageUrl}
            showError={Boolean(formik?.touched.elements?.[index])}
            errorMessage={formik?.errors.elements?.[index].toString()}
            setDisabled={false}
            isOpenModal={true}
            useCropper={false}
          />
        </div>
      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )

}

export default PitchDeck;