import MembersComponent from "./members.component";
import ArticlesComponent from "./articles.component";
import IntroComponent from "./intro.component";
import ImageAndTextComponent from "./image-and-text.component";
import ImageAndListComponent from "./image-and-list.component";
import CardsComponent from "./cards.component";
import StepsComponent from "./steps.component";
import BecomeMemberComponent from "./become-member.component";
import HeroComponent from "./hero.component";
import HeroV2Component from "./herov2.component";
import DarkHeadline from "./dark-headline.component";
import TextColumns from "./text-columns.component";
import PitchDeck from "./pitchdeck.component";
import Invest from "./invest.component";
import Announcement from "./announcement.component";
import Partners from "./partners.component";

export const componentsList = [
  {
    id: "hero",
    name: "Hero",
    component: HeroComponent
  },
  {
    id: "herov2",
    name: "Hero V2",
    component: HeroV2Component
  },
  {
    id: 'members',
    name: 'Members',
    component: MembersComponent
  },
  {
    id: 'articles',
    name: 'Articles',
    component: ArticlesComponent
  },
  {
    id: 'intro',
    name: 'Intro',
    component: IntroComponent
  },
  {
    id: 'image-and-text',
    name: 'Image and Text',
    component: ImageAndTextComponent
  },
  {
    id: 'image-and-list',
    name: 'Image and List',
    component: ImageAndListComponent
  },
  {
    id: 'cards',
    name: 'Cards',
    component: CardsComponent
  },
  {
    id: 'steps',
    name: 'Steps',
    component: StepsComponent
  },
  {
    id: 'become-member',
    name: 'Become Member',
    component: BecomeMemberComponent
  },
  {
    id: 'dark-headline',
    name: 'Dark Headline',
    component: DarkHeadline
  },
  {
    id: 'text-columns',
    name: 'Text columns',
    component: TextColumns
  },
  {
    id: 'pitchdeck',
    name: 'Pitch Deck',
    component: PitchDeck
  },
  {
    id: 'invest',
    name: 'Invest',
    component: Invest
  },
  {
    id: 'announcement',
    name: 'Announcement',
    component: Announcement
  },
  {
    id: 'partners',
    name: 'Partners',
    component: Partners
  }
]