import React, { CSSProperties, useEffect, useRef } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./particles-config.json"
import { loadSlim } from "@tsparticles/slim";
import { Button } from "./components/button.component"
import { cn } from "../../../utils";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";
import ImageUpload from "../../atoms/image-upload";
import { CKEditor as CKEditorComponent } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5";
import { useUploadFileMutation } from "../../../services/auth";
import { FileLoader, UploadAdapter } from "@ckeditor/ckeditor5-upload/src/filerepository";
import { editorConfig } from "../../molecules/editor/editor.config"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

export type TextColumnsProps = {
  title: string
  columns?: {
    title?: string
    description?: string
    buttonText?: string
    buttonUrl?: string
  }[]
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const  TextColumns = ({ title, columns, formik, index }: TextColumnsProps) => {
  const [editMode, setEditMode] = React.useState(false);
  const [uploadFile] = useUploadFileMutation();

  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const res = await uploadFile({ image: file }).unwrap();
            resolve({
              default: res?.data?.image_name,
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => {},
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return uploadAdapter(loader);
    };
  }

  const customEditorConfig = {
    ...editorConfig,
    extraPlugins: [uploadPlugin],
  };

  const triggerAddColumn = () => {
    const existingBoxes = formik.values.elements?.[index]?.params?.columns || []
    formik.setFieldValue(`elements.${index}.params.columns`, [
      ...existingBoxes,
      {
        title: "",
        description: "",
        buttonText: "",
        buttonUrl: ""
      }
    ])
  }

  const triggerRemoveColumn = (boxIndex: number) => {
    const existingBoxes = formik.values.elements?.[index]?.params?.columns || []
    formik.setFieldValue(`elements.${index}.params.columns`, [
      ...existingBoxes.filter((box: any, index: number) => index !== boxIndex)
    ])
  }

  if(!editMode) {
    return (
      <div className={cn("py-[40px] relative group")}>
        <div className="container">
          {title ? <h1 className="text-[3.5rem] leading-[3.5rem] mb-20 font-bold">{title}</h1> : null}
          <div className="flex flex-row flex-nowrap gap-10">
            {columns?.map((column, index) => (
              <div className="w-full relative" key={index}>
                {column.title ? <div className="text-[3rem] leading-[3.5rem] font-bold underline  mb-20"
                                     dangerouslySetInnerHTML={{ __html: column.title?.replace(/\n/g, "<br />") || '' }} /> : null}
                {column.description ? <div className="text-md" dangerouslySetInnerHTML={{ __html: column.description?.replace(/\n/g,"<br />") || ''}} /> : null}
                {column.buttonText && column.buttonUrl ? (
                  <div className="flex justify-end mt-10">
                    <a href={column.buttonUrl}>
                      <Button className="rounded-3xl py-10">
                        {column.buttonText}
                      </Button>
                    </a>
                  </div>
                ) : null}


              </div>
            ))}
          </div>
        </div>

        <div
          className="absolute z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
        <TextInput
          name={`elements.${index}.params.title`}
          type="text"
          label="Section title"
          value={formik?.values.elements[index]?.params?.title}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <hr />

        <div className="grid grid-cols-1 gap-4 pl-40">
          {formik?.values.elements[index]?.params?.columns?.map((column: any, columnIndex: number) => (
            <div key={columnIndex} className="flex flex-row gap-4 border-b pb-20 mb-20">
              <div className="flex flex-col gap-4 w-full">
                <TextInput
                  name={`elements.${index}.params.columns.${columnIndex}.title`}
                  type="text"
                  label="Title"
                  value={formik?.values.elements[index]?.params?.columns?.[columnIndex]?.title}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                />
                <div>
                  <label>Description</label>
                  <CKEditorComponent
                    config={customEditorConfig}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik?.setFieldValue(`elements.${index}.params.columns.${columnIndex}.description`, data);
                    }}
                    data={formik?.values.elements[index]?.params?.columns?.[columnIndex]?.description || ""}
                    onReady={() => {
                    }}
                  />
                </div>
                <TextInput
                  name={`elements.${index}.params.columns.${columnIndex}.buttonText`}
                  type="text"
                  label="Button text"
                  value={formik?.values.elements[index]?.params?.columns?.[columnIndex]?.buttonText}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                />
                <TextInput
                  name={`elements.${index}.params.columns.${columnIndex}.buttonUrl`}
                  type="text"
                  label="Button url"
                  value={formik?.values.elements[index]?.params?.columns?.[columnIndex]?.buttonUrl}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                />

                <div
                  className="flex justify-end">
                  <CustomButton
                    variant={"danger"}
                    onClick={() => triggerRemoveColumn(columnIndex)}
                    title="Remove Column"
                  />
                </div>

              </div>
            </div>
          ))}


        </div>
        <CustomButton
          onClick={triggerAddColumn}
          title="Add column"
        />
      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )

};

export default TextColumns;
