import React from "react";
import CustomButton from "../../atoms/button";
import { FormikProps } from "formik/dist/types";
import TextInput from "../../atoms/text-input";
import { Button } from "./components/button.component";
import { MemberCard } from "./components/member-card.component";
import { LandingPageItem } from "./types";

type MembersComponentProps = {
  title?: string;
  showProfessionsList?: boolean,
  carousel?: boolean,
  filters: {
    profession?: string,
    featured?: boolean,
    verified?: boolean,
    limit?: number
  }
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const MembersComponent = ({ title, formik, index, showProfessionsList, carousel, filters }: MembersComponentProps) => {
  const [editMode, setEditMode] = React.useState(false);

  // if(!filters) {
  //   filters = {
  //     profession: '',
  //     featured: false,
  //     verified: false,
  //     limit: 8
  //   }
  //   formik.setFieldValue(`elements.${index}.filters`, filters)
  // }

  if(!editMode) {
    return (
      <div className="p-24 min-h-[300px] relative group">

        <div className="flex flex-nowrap flex-row mb-20">
          <div
            className="grow shrink-0 font-bold text-6xl leading-tight max-w-full overflow-ellipsis break-all">
            {title}
          </div>
          <div
            className="flex items-center md:items-end mt-6 md:mt-0 w-full overflow-hidden pl-0 md:pl-20 md:max-w-[50%] gap-4">
            <Button className="whitespace-nowrap">All</Button>
            <Button className="whitespace-nowrap">Account Delivery Manager</Button>
            <Button className="whitespace-nowrap">Account Executive</Button>
            <Button className="whitespace-nowrap">Etc..</Button>
          </div>
        </div>
        <div
          className={`grid gap-4 grid-cols-4`}>
          {Array(filters?.limit ?? 8).fill(0).map((_, i) => (
            <MemberCard
              key={i}
              name={`Member ${i+1}`}
              role={['Account Delivery Manager', 'Account Executive', 'Author and Speaker'][i % 3] || ''}
              image="https://placehold.co/400?text=User+Image"
            />
          ))}
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
        <TextInput
          name={`elements.${index}.params.title`}
          type="text"
          label="Section title"
          value={formik?.values.elements[index]?.params?.title}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <hr />
        <h3>Filters</h3>

        <TextInput
          name={`elements.${index}.params.filters.profession`}
          type="text"
          label="Profession"
          placeholder="To filter by multiple professions, separate them with commas (eg: 'Account Executive, Account Delivery Manager')"
          value={formik?.values.elements[index]?.params?.filters?.profession}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />

        <TextInput
          name={`elements.${index}.params.filters.limit`}
          type="number"
          label="Number of members to display"
          placeholder="Leave empty to display all"
          value={formik?.values.elements[index]?.params?.filters?.limit}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
        />

        <TextInput
          name={`elements.${index}.params.filters.featured`}
          type="checkbox"
          label="Show only featured members"
          value={formik?.values.elements[index]?.params?.filters?.featured}
          checked={!!formik?.values.elements[index]?.params?.filters?.featured}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
          containerClassName="flex flex-row flex-nowrap gap-2 items-reverse"
        />


        <hr />
        <h3>Display</h3>
        <TextInput
          name={`elements.${index}.params.showProfessionsList`}
          type="checkbox"
          label="Display professions filter"
          value={formik?.values.elements[index]?.params?.showProfessionsList}
          checked={!!formik?.values.elements[index]?.params?.showProfessionsList}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
          containerClassName="flex flex-row flex-nowrap gap-2 items-reverse"
        />
        <TextInput
          name={`elements.${index}.params.carousel`}
          type="checkbox"
          label="Display as carousel"
          value={formik?.values.elements[index]?.params?.carousel}
          checked={!!formik?.values.elements[index]?.params?.carousel}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
          containerClassName="flex flex-row flex-nowrap gap-2 items-reverse"
        />

      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}
export default MembersComponent