"use client";

import React from "react";
import moment from "moment";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";
import CustomDatePicker from "../../atoms/date-picker";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import dayjs from "dayjs";
import { HexColorPicker } from "react-colorful";

type HexColor<S extends string = string> = `#${S}`;

export type AnnouncementProps = {
  title?: string
  description?: string
  date?: string
  headerColor?: HexColor

  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const Announcement =  ({ title, description, date, headerColor, formik, index }: AnnouncementProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const humanTimeUntil = (date: Date) => {
    const now = moment();
    const end = moment(date);

    const duration = moment.duration(end.diff(now));
    const days = duration.asDays();
    const months = duration.asMonths();
    const years = duration.asYears();

    if (years >= 1) {
      return `${Math.floor(years)} years and ${Math.floor(months % 12)} months`;
    }
    if (months >= 1) {
      return `${Math.floor(months)} months and ${Math.floor(days % 30)} days`;
    }
    return `${Math.floor(days)} days`;
  }
  // this regex
  const re = /{date}/gi;

  title = moment(date).isValid() ? title?.replace(re, humanTimeUntil(moment(date).toDate())) : title;
  description = moment(date).isValid() ? description?.replace(re, humanTimeUntil(moment(date).toDate())) : description;

  if(!editMode) {
    return (
      <div className="py-[80px] relative">
        <div className="container">
          <h1
            className="text-[3rem] leading-[3rem] md:text-[5rem] md:leading-[5rem] text-center font-bold max-w-[80%] mx-auto text-primary-color"
            style={{ color: headerColor }}
          >
            {title}
          </h1>
          <div className="text-[1rem] leading[1rem] max-w-[60%] mx-auto mt-20">
            {description}
          </div>
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24 flex flex-col gap-4">
      <TextInput
        name={`elements.${index}.params.title`}
        type="text"
        label="Section title"
        placeholder="Type {date} to show the time until the date"
        value={formik?.values.elements[index]?.params?.title}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
      />
      <HexColorPicker color={formik?.values.elements[index]?.params?.headerColor} onChange={(color) => {
        formik?.setFieldValue(`elements.${index}.params.headerColor`, color);
      }} />;

      <TextInput
        name={`elements.${index}.params.description`}
        type="text"
        label="Section description"
        placeholder="Type {date} to show the time until the date"
        value={formik?.values.elements[index]?.params?.description}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
      />
      <CustomDatePicker
        name="date"
        label="Time until"
        type="text"
        containerClassName="date-of-birth-date-picker"
        value={formik?.values?.elements[index]?.params?.date}
        onChange={(date: any, dateString: any) => formik?.setFieldValue(`elements.${index}.params.date`, dayjs(date).format("YYYY-MM-DD"))}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        showError={Boolean(formik?.touched.elements?.[index])}
        required={false}
        disabledDate={false}
      />

      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}

export default Announcement;