"use client";

import React from "react";
import { Button } from "./components/button.component";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import { cn } from "../../../utils";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";
import { CKEditor as CKEditorComponent } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5";
import ImageUpload from "../../atoms/image-upload";

export type PartnersProps = {
  title?: string
  darkMode?: boolean
  items?: {
    imageUrl: string
    url: string
  }[]

  leftButton?: {
    text: string
    url: string
  }
  rightButton?: {
    text: string
    url: string
  }

  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const Partners =  ({ title, items, leftButton, rightButton, darkMode = true, formik, index }: PartnersProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const triggerAddPartner = () => {
    const existingPartners = formik.values.elements?.[index]?.params?.items || []
    formik.setFieldValue(`elements.${index}.params.items`, [
      ...existingPartners,
      {
        imageUrl: "",
        url: "",
      }
    ])
  }

  const triggerRemovePartner = (boxIndex: number) => {
    const existingBoxes = formik.values.elements?.[index]?.params?.columns || []
    formik.setFieldValue(`elements.${index}.params.columns`, [
      ...existingBoxes.filter((box: any, index: number) => index !== boxIndex)
    ])
  }

  if(!editMode) {
    return (
      <div className={cn(
        "py-[80px] flex flex-row items-center bg-black text-white relative",
        !darkMode && "bg-transparent text-black"
      )}>
        <div className="container px-[20px] sm:px-0">
          <h1
            className="font-bold text-6xl md:text-8xl leading-[4rem] md:leading-[6rem] mb-[40px] text-center"
          >
            {title || 'Tile here'}
          </h1>

          <div className="flex flex-row gap-10 items-center justify-center my-20">
            {items?.map((item, index) => (
              <div key={index} className="p-5">
                <a href={item.url} target="_blank" rel="noreferrer" className="cursor-pointer">
                  <img src={item.imageUrl} alt="Partner Logo" className="h-[120px] object-contain" />
                </a>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-10 justify-center">
            {leftButton?.text && leftButton?.url && (
              <Button
                as={'a'}
                variant="primary"
                href={leftButton.url}
                className="whitespace-nowrap px-20 py-5"
              >
                {leftButton.text}
              </Button>
            ) || null}

            {rightButton?.text && rightButton?.url && (
              <Button
                as={'a'}
                variant="secondary"
                href={rightButton.url}
                className="whitespace-nowrap px-20 py-5"
              >
                {rightButton.text}
              </Button>
            ) || null}
          </div>
        </div>
        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24 flex flex-col gap-4">
      <TextInput
        name={`elements.${index}.params.darkMode`}
        type="checkbox"
        label="Dark mode"
        value={formik?.values.elements[index]?.params?.darkMode}
        checked={!!formik?.values.elements[index]?.params?.darkMode}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
        containerClassName="flex flex-row flex-nowrap gap-2 items-reverse"
      />
      <TextInput
        name={`elements.${index}.params.title`}
        type="text"
        label="Section title"
        placeholder="Section title"
        value={formik?.values.elements[index]?.params?.title}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
      />

      <div className="flex flex-row gap-10">
        <div className="flex flex-col gap-4 w-full">
          <TextInput
            name={`elements.${index}.params.leftButton.text`}
            type="text"
            label="Left button text"
            placeholder="Left button text"
            value={formik?.values.elements[index]?.params?.leftButton?.text}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />
          <TextInput
            name={`elements.${index}.params.leftButton.url`}
            type="text"
            label="Left button url"
            placeholder="Left button url"
            value={formik?.values.elements[index]?.params?.leftButton?.url}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />
        </div>
        <div className="flex flex-col gap-4 w-full">
          <TextInput
            name={`elements.${index}.params.rightButton.text`}
            type="text"
            label="Right button text"
            placeholder="Right button text"
            value={formik?.values.elements[index]?.params?.rightButton?.text}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />
          <TextInput
            name={`elements.${index}.params.rightButton.url`}
            type="text"
            label="Right button url"
            placeholder="Right button url"
            value={formik?.values.elements[index]?.params?.rightButton?.url}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />
        </div>
      </div>


      {formik?.values.elements[index]?.params?.items?.map((item: any, itemIndex: number) => (
        <div key={itemIndex} className="flex flex-row gap-4 border-b pb-20 mb-20">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row gap-4">
              <div className={"w-full"}>
                <TextInput
                  name={`elements.${index}.params.items.${itemIndex}.url`}
                  type="text"
                  label="Url"
                  value={formik?.values.elements[index]?.params?.items?.[itemIndex]?.url}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                  className={"w-full mb-10"}
                />

                <div>
                  <CustomButton
                    variant={"danger"}
                    onClick={() => triggerRemovePartner(itemIndex)}
                    title="Remove Column"
                  />
                </div>
              </div>
              <ImageUpload
                label="Image"
                name={`elements.${index}.params.items.${itemIndex}.imageUrl`}
                value={item.imageUrl}
                className="bg-gray-400"
                onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.items.${itemIndex}.imageUrl`, img)}
                image={item.imageUrl}
                showError={Boolean(formik?.touched.elements?.[index])}
                errorMessage={formik?.errors.elements?.[index].toString()}
                setDisabled={false}
                isOpenModal={true}
                useCropper={false}
              />
            </div>







          </div>
        </div>
      ))}


      <CustomButton
        onClick={triggerAddPartner}
        title="Add partner"
      />


      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>

    </div>
  )


}

export default Partners;