"use client";

import React, { CSSProperties, useEffect, useRef } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesConfig from "./particles-config.json"
import { loadSlim } from "@tsparticles/slim";
import { Button } from "./components/button.component"
import { cn } from "../../../utils";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";
import ImageUpload from "../../atoms/image-upload"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

export type HeroV2Props = {
  title: string
  description: string
  buttonText?: string
  buttonHref?: string
  boxes?: {
    title: string
    description: string
    image: string
    url: string
  }[]
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const HeroV2 = ({ title, description, buttonText, buttonHref, boxes, formik, index }: HeroV2Props) => {
  const [editMode, setEditMode] = React.useState(false);
  const ref = useRef<HTMLDivElement | null>(null)
  const [init, setInit] = React.useState(false);

  const onSkipHeroClick = () => {
    window.scrollTo({
      top: (ref.current?.clientHeight ?? 0) - 120,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    initParticlesEngine(async (engine: any) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const colors = [
    'bg-weird-box-100',
    'bg-weird-box-200',
    'bg-weird-box-300',
    'bg-weird-box-400',
    'bg-weird-box-500',
    'bg-weird-box-600',
    'bg-weird-box-700',
    'bg-weird-box-800',
    'bg-weird-box-900',
    'bg-weird-box-1000',
    'bg-weird-box-1100',
    'bg-weird-box-1200',
  ];

  const Box = ({ color, children, className, style }: { color: string, children: React.ReactNode | React.ReactNode[]; className?: string; style?: CSSProperties | undefined }) => {
    return (
      <div className={cn(`${color} relative rounded-br-[50px] max-w-full`, className)} style={style}>
        {children}
      </div>
    );
  };

  const NestedBoxes = ({ children, className }: { children: React.ReactNode | React.ReactNode[] , className?: string }) => {
    let boxes; // declare boxes variable

    for (let i = 0; i < colors.length; i++) {
      if(i === 0) {
        // If it's the first box, include the title and text
        boxes = <Box color={colors[i]} className={cn(" text-white p-20", className)}>{children}</Box>;
      } else {
        // Nest each subsequent box inside the previous one
        boxes = <Box
          color={colors[i]}
          className={''}
          style={{
            transform: `translate(-15px, -15px)`,
        }}
        >{boxes}</Box>;
      }
    }

    return (
      // padding 110px to the left to align it back with the rest of the content
      <div className="pl-[180px]">
        {boxes}
      </div>
    );
  };


  const triggerAddBox = () => {
    const existingBoxes = formik.values.elements?.[index]?.params?.boxes || []
    formik.setFieldValue(`elements.${index}.params.boxes`, [
      ...existingBoxes,
      { title: '', description: '', image: '', url: '' }
    ])
  }

  const triggerRemoveBox = (boxIndex: number) => {
    const existingBoxes = formik.values.elements?.[index]?.params?.boxes || []
    formik.setFieldValue(`elements.${index}.params.boxes`, [
      ...existingBoxes.filter((box: any, index: number) => index !== boxIndex)
    ])
  }

  console.log({
    title, description, buttonText, buttonHref, boxes, formik, index
  })

  if(!editMode) {
    return (
      <div
        id="hero"
        ref={ref}
        className={cn(
          "header w-full flex relative flex-col group",
        )}
      >
        <div className="h-screen relative w-full">
          <div className="w-full h-full z-[1] absolute inset-0 overflow-hidden">
            {init && <Particles
              id="tsparticles"
              options={particlesConfig as any}
              className="w-full h-full absolute overflow-hidden"
              style={{ position: "static" }}
            /> || null}
          </div>
          <div className="absolute inset-0 z-[2] pt-[120px] flex items-center justify-center">
            <div className="container relative flex justify-center items-center">
              <div className="max-w-full">
                {title ? (
                  <NestedBoxes className="w-full">
                    <h1 className="text-[40px] leading-[40px] mb-5  md:text-[120px] md:leading-[120px] font-bold "
                        dangerouslySetInnerHTML={{ __html: title?.replace(/\n/g, "<br />") || '' }} />
                    <div className="flex justify-between gap-4 flex-col md:flex-row md:gap-10">
                      <div className="text-sm"
                           dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, "<br />") || '' }} />
                      <div>
                        {buttonText && buttonHref && (
                          <a href={buttonHref}>
                            <Button
                              size="lg"
                              className="text-nowrap rounded-[30px]"
                            >
                              {buttonText}
                            </Button>
                          </a>
                        ) || null}
                      </div>
                    </div>
                  </NestedBoxes>
                ) : null}
              </div>
            </div>

            <div className="absolute bottom-[150px] flex items-center justify-center w-full z-10">
              <button className="cursor-pointer p-[0px] bottom-[70px]  mx-auto" onClick={onSkipHeroClick}
                      role="button">
                <span className="icons scroll-to-content !h-[60px] !w-[34px]" />
              </button>
            </div>
          </div>
        </div>
        {boxes?.length ? (
          <div className="container -mt-[70px] relative z-10 px-20">
            <div className="flex flex-col md:flex-row gap-10">
              {boxes.map((box, index) => (
                <div className={cn(
                  "p-10  transition-all hover:-translate-y-[24px]",
                  index === 0 && "bg-primary-color text-black",
                  index === 1 && "bg-white text-black",
                  index === 2 && "bg-black text-white",
                )} key={index}>
                  <div className="text-[40px] uppercase font-bold">{box.title}</div>
                  <div className="">{box.description}</div>
                  {box.image ? (<img src={box.image} alt="Members" className="w-full" />) : null}
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <div
          className="absolute z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
        <TextInput
          name={`elements.${index}.params.title`}
          type="text"
          label="Section title"
          value={formik?.values.elements[index]?.params?.title}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <TextInput
          name={`elements.${index}.params.description`}
          type="text"
          label="Description"
          value={formik?.values.elements[index]?.params?.description}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <TextInput
          name={`elements.${index}.params.buttonText`}
          type="text"
          label="Button text"
          value={formik?.values.elements[index]?.params?.buttonText}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <TextInput
          name={`elements.${index}.params.buttonHref`}
          type="text"
          label="Button link"
          value={formik?.values.elements[index]?.params?.buttonHref}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <hr />

        <div className="grid grid-cols-1 gap-4 pl-40">
          {formik?.values.elements[index]?.params?.boxes?.map((box: any, boxIndex: number) => (
            <div key={boxIndex} className="flex flex-row gap-4 border-b pb-20 mb-20">
              <div className="flex flex-col gap-4 w-full">
                <TextInput
                  name={`elements.${index}.params.boxes.${boxIndex}.title`}
                  type="text"
                  label="Box title"
                  value={formik?.values.elements[index]?.params?.boxes?.[boxIndex]?.title}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                />
                <TextInput
                  name={`elements.${index}.params.boxes.${boxIndex}.description`}
                  type="text"
                  label="Box description"
                  value={formik?.values.elements[index]?.params?.boxes?.[boxIndex]?.description}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                />

                <TextInput
                  name={`elements.${index}.params.boxes.${boxIndex}.url`}
                  type="text"
                  label="Box url"
                  value={formik?.values.elements[index]?.params?.boxes?.[boxIndex]?.url}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.elements?.[index]?.toString()}
                  required={true}
                />

                <CustomButton
                  variant="danger"
                  onClick={() => triggerRemoveBox(boxIndex)}
                  title="Remove box"
                />
              </div>
              <div className="w-[320px]">
                <ImageUpload
                  label="Image"
                  name={`elements.${index}.params.imageUrl`}
                  value={box.image}
                  className="bg-gray-400 w-full"
                  onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.boxes.${boxIndex}.image`, img)}
                  image={box.image}
                  showError={Boolean(formik?.touched.elements?.[index])}
                  errorMessage={formik?.errors.elements?.[index].toString()}
                  setDisabled={false}
                  isOpenModal={true}
                  useCropper={false}
                />
              </div>
            </div>
          ))}


        </div>
        <CustomButton
          onClick={triggerAddBox}
          disabled={(boxes?.length || 0) >= 3}
          title="Add box"
        />
      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )

};

export default HeroV2;
