
import React, { useRef } from "react";
import ImageUpload from "../../atoms/image-upload";
import CustomButton from "../../atoms/button";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import { ClassicEditor } from "ckeditor5";
import { CKEditor as CKEditorComponent } from "@ckeditor/ckeditor5-react";
import { editorConfig } from "../../molecules/editor/editor.config";
import { useUploadFileMutation } from "../../../services/auth";
import { FileLoader, UploadAdapter } from "@ckeditor/ckeditor5-upload/src/filerepository";

export type DarkHeadlineProps = {
  title?: string
  description?: string

  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const DarkHeadline = ({ title, description, formik, index }: DarkHeadlineProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const [uploadFile] = useUploadFileMutation();

  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const res = await uploadFile({ image: file }).unwrap();
            resolve({
              default: res?.data?.image_name,
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => {},
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return uploadAdapter(loader);
    };
  }

  const customEditorConfig = {
    ...editorConfig,
    extraPlugins: [uploadPlugin],
  };

  if(!editMode) {
    return (
      <div className="bg-black text-white relative min-h-[200px] group">
        <div className="container py-[40px]">
          <h1 className="text-[3.5rem] leading-[3.5rem] mb-20 font-bold" dangerouslySetInnerHTML={{ __html: title?.replace(/\n/g, "<br />") || "" }} />
          <div className="font-light text-3xl" dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, "<br />") || "" }} />
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-10">
        <div>
          <label>Section Title</label>
          <CKEditorComponent
            config={customEditorConfig}
            editor={ClassicEditor}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik?.setFieldValue(`elements.${index}.params.title`, data);
            }}
            data={title || ''}
            onReady={() => {}}
          />
        </div>

        <div>
          <label>Section Description</label>
          <CKEditorComponent
            config={customEditorConfig}
            editor={ClassicEditor}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik?.setFieldValue(`elements.${index}.params.description`, data);
            }}
            data={description || ''}
            onReady={() => {
            }}
          />
        </div>
      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
};

export default DarkHeadline;
