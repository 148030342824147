import React from "react";
import Label from "../label";
import { DatePicker } from "antd";
import ErrorLabel from "../error-label";
import { ICustomDatePicker } from "./custom-date-picker.types";
import "./custom-date-picker.css";
import dayjs from "dayjs";

export const disableFutureDates = (current: any) => {
  // Always return true to disable all dates
  return current && current.valueOf() > Date.now();
};

const CustomDatePicker = ({
  id,
  name,
  label,
  placeholder,
  disabled,
  value,
  required = false,
  errorMessage = "",
  showError,
  onChange,
  containerClassName = "",
  disabledDate = disableFutureDates,
}: ICustomDatePicker) => {


  return (
    <div
      className={`${containerClassName} ${
        errorMessage && showError ? "date-error" : ""
      }`}
    >
      {label && (
        <Label
          id={id || name}
          name={label}
          required={required}
          className=""
        />
      )}
      <div className="relative">
        <DatePicker
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          value={value ? dayjs(value) : null}
          disabledDate={disabledDate}
          allowClear={true}
          format={"DD-MM-YYYY"}
        />

        {showError && <ErrorLabel message={errorMessage} />}
      </div>
    </div>
  );
};

export default CustomDatePicker;
