import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autosave,
  Bold,
  Underline,
  Strikethrough,
  CloudServices,
  Essentials,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  List,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  TodoList,
  Undo,
  ImageResizeHandles,
  ListProperties,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  MediaEmbed,
} from "ckeditor5";
import VideoResize from "./video-resize-plugin";
import MediaResizePlugin from "./resize-widget-plugin";

import "ckeditor5/ckeditor5.css";

export const editorConfig: any = {
  mediaEmbed: {
    previewsInData: true,
    resizeOptions: [
      {
        name: "resizeVideo:original",
        value: null,
        label: "Original",
      },
      {
        name: "resizeVideo:50",
        value: "50%",
        label: "50%",
      },
      {
        name: "resizeVideo:75",
        value: "75%",
        label: "75%",
      },
    ],
    toolbar: [
      'resizeVideo',
    ],
    providers: [
      {
        name: "dailymotion",
        url: /^dailymotion\.com\/video\/(\w+)/,
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
            `<iframe src="https://www.dailymotion.com/embed/video/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" width="480" height="270" allowfullscreen allow="autoplay">' +
            "</iframe>" +
            "</div>"
          );
        },
      },

      {
        name: "spotify",
        url: [/^https?:\/\/open\.spotify\.com\/(\w+\/\w+)/],
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 50%;">' +
            `<iframe src="https://open.spotify.com/embed/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" allowtransparency="true" allow="encrypted-media">' +
            "</iframe>" +
            "</div>"
          );
        },
      },
      {
        name: "apple",
        url: [/^https?:\/\/podcasts\.apple\.com\/(\w+\/\w+)/],
        html: (match: any) => {
          const url: any = match.input;
          const replacedUrl = url.replace("https://", "https://embed.");

          return `<iframe
                        allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                        frameborder="0"
                        height="175"
                        style="width:100%;overflow:hidden;border-radius:10px;"
                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                        src=${replacedUrl}
                      ></iframe>`;
        },
      },

      {
        name: "youtube",
        url: [
          /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
          /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
          /^youtube\.com\/embed\/([\w-]+)/,
          /^youtu\.be\/([\w-]+)/,
          /^youtube\.com\/shorts\/([\w-]+)/, // New pattern for YouTube shorts
        ],
        html: (match: any) => {
          const id = match[1];
          return `
        <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
          <div class="cookieconsent-optout-marketing">
            Please
            <a href="javascript:Cookiebot.renew()">accept marketing cookies</a>
            to watch this video.
          </div>
          <iframe src="https://www.youtube.com/embed/${id}" data-cookieconsent="marketing"
            style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
            frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
          </iframe>
        </div>`;
        },
      },

      {
        name: "vimeo",
        url: [
          /^vimeo\.com\/(\d+)/,
          /^vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+)/,
          /^vimeo\.com\/album\/[^/]+\/video\/(\d+)/,
          /^vimeo\.com\/channels\/[^/]+\/(\d+)/,
          /^vimeo\.com\/groups\/[^/]+\/videos\/(\d+)/,
          /^vimeo\.com\/ondemand\/[^/]+\/(\d+)/,
          /^player\.vimeo\.com\/video\/(\d+)/,
        ],
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
            `<iframe src="https://player.vimeo.com/video/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
            "</iframe>" +
            "</div>"
          );
        },
      },

      {
        name: "instagram",
        url: /^instagram\.com\/p\/(\w+)/,
      },
      {
        name: "twitter",
        url: /^twitter\.com/,
      },
      {
        name: "googleMaps",
        url: /^google\.com\/maps/,
      },
      {
        name: "flickr",
        url: /^flickr\.com/,
      },
      {
        name: "facebook",
        url: /^facebook\.com/,
      },
    ],
  },
  toolbar: {
    items: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "alignment",
      "|",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
      "imageUpload",
      "mediaEmbed",
      "|",
      "insertTable",
      "resizeMedia25",
      "resizeMedia50",
      "resizeMedia75",
      "resizeMedia100"
    ],
  },
  image: {
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:inline",
      "imageStyle:wrapText",
      "imageStyle:breakText",
      "|",
      "resizeImage",
    ],
  },
  placeholder: "Add a body...",
  plugins: [
    Autosave,
    Alignment,
    Bold,
    Underline,
    Strikethrough,
    Essentials,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    ImageResizeHandles,
    ImageToolbar,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    List,
    ListProperties,
    LinkImage,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Paragraph,
    SelectAll,
    TodoList,
    Undo,
    MediaEmbed,
    VideoResize,
    MediaResizePlugin
  ],
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
    ],
  },
};
