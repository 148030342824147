"use client";

import React from "react";
import { Button } from "./components/button.component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Range } from "react-range";
import { cn } from "../../../utils";
import { FormikProps } from "formik/dist/types";
import { LandingPageItem } from "./types";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";

export type InvestProps = {
  title?: string
  subtitle?: string
  description?: string

  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const Invest =  ({ title, description, subtitle, index, formik }: InvestProps) => {
  const [editMode, setEditMode] = React.useState(false);

  // Slider settings
  const STEP = 1000
  const MIN = 10000
  const MAX = 200000

  const investFormik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      amount: 50000,
    },
    onSubmit: (values, formikHelpers) => {
      console.log(values)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      surname: Yup.string().required('Surname is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone: Yup.string().required('Phone is required'),
    }),
  })

  if(!editMode) {
    return (
      <div className="py-[80px] relative">
        <div className="container">
          <div className="flex flex-row gap-20">
            <div className="w-full">
              <h3 className="text-[3rem] font-medium">{title || "You slide the amount you want to give!"}</h3>
              <div className="text-4xl font-light">{subtitle || "Starting from €10.000 to €200.000"}</div>

              <div className="my-20 py-[40px]">
                <Range
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  values={[investFormik.values?.amount || 0]}
                  onChange={(values) => investFormik.setFieldValue("amount", values[0])}
                  renderMark={({ props, index }) => {
                    const firstIndex = 0;
                    const lastIndex = (MAX - MIN) / STEP;

                    if (index > firstIndex && index < lastIndex) return null;

                    return (
                      <div
                        {...props}
                        className={cn(
                          "w-5 h-5 bg-primary-color rounded-full",
                          index === lastIndex && "w-10 h-10"
                        )}
                      />
                    );
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      className="border-b-[2px] border-b-primary-color border-dashed h-[2px] w-full"
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props, value }) => (
                    <div
                      {...props}
                      className="outline-0 relative"
                    >
                      <div className="flex flex-row flex-nowrap gap-2">
                        <div className="border-3 border-primary-color rounded-full p-4">
                          <div className="w-5 h-5 bg-primary-color rounded-full" />
                        </div>
                      </div>

                      <div
                        className="absolute bottom-[-30px] text-primary-color text-lg font-medium text-center w-full">
                        {Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "EUR",
                          maximumFractionDigits: 0
                        }).format(value)}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className="text-4xl font-light">{description}</div>
              <div className="mt-10">
                <Button className="px-20 py-10 rounded-3xl">
                  Contact us
                </Button>
              </div>
            </div>
            <div className="w-[30%] bg-black rounded-[50px] p-20 flex flex-col gap-2 shrink-0">
              <Input formik={investFormik} name={"name"} label={"Name"} />
              <Input formik={investFormik} name={"surname"} label={"Surname"} />
              <Input formik={investFormik} name={"email"} label={"Email"} type={"email"} />
              <Input formik={investFormik} name={"phone"} label={"Phone"} type={"tel"} />

              <div className="mt-20">
                <Button
                  className="px-20 py-10 rounded-3xl uppercase text-black w-full"
                  onClick={() => investFormik.handleSubmit()}
                  disabled={investFormik.isSubmitting || !investFormik.isValid}
                >
                  Send request
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24">
      <TextInput
        name={`elements.${index}.params.title`}
        type="text"
        label="Section title"
        value={formik?.values.elements[index]?.params?.title}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
        className="mb-4"
      />
      <TextInput
        name={`elements.${index}.params.subtitle`}
        type="text"
        label="Section subtitle"
        value={formik?.values.elements[index]?.params?.subtitle}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
        className="mb-4"
      />
      <TextInput
        name={`elements.${index}.params.description`}
        type="text"
        label="Section description"
        value={formik?.values.elements[index]?.params?.description}
        showError={Boolean(formik?.touched.elements?.[index])}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        errorMessage={formik?.errors.elements?.[index]?.toString()}
        required={true}
        className="mb-4"
      />

      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}

const Input = ({ name, label, type = "text", formik }: {
  name: "name" | "surname" | "email" | "phone",
  label: string,
  type?: "text" | "email" | "tel",
  formik: any
}) => {
  const hasError = formik.touched[name] && formik.errors[name];
  return (
    <div>
      <input
        type={type}
        id={name}
        placeholder={label}
        className="w-full px-20 py-10 rounded-3xl bg-darkish-input-background text-darkish-input-text placeholder:text-darkish-input-text outline-0"
        {...formik.getFieldProps(name)}
      />
      {hasError && <div className="text-red-500 text-xs pl-15">{formik.errors[name]}</div> || null}
    </div>
  )
}

export default Invest;